import { useTranslation } from 'react-i18next';
import "./load-translations.styles.css";

const LoadTranslations = (): JSX.Element => {
	const { t } = useTranslation();

	return (
		<div className='loading'>{t("Loading")}</div>
	)
}

export default LoadTranslations;
import LangSelector from "../lang-selector/lang-selector";
import { Navbar , Container, Nav} from "react-bootstrap";
import "./navigation.styles.css";
import mailSVG from "./mail.svg";
import phoneSVG from "./phone.svg";
import { email, tel } from "../footer-content/footer-content";

const Navigation = (): JSX.Element => {

	return (
		<Navbar style={{backgroundColor: "rgb(19, 16, 211)"}} fixed="top">
			<Container>
				<Navbar.Text className="nav-logo-custom">Ab FinInvest Kotka Oy</Navbar.Text>
				<Navbar.Toggle />
				<Navbar.Collapse className="justify-content-end">
				<Nav >
					<Nav.Link href={`mailto:${email}`}> 
						<div>
							<img src={mailSVG} alt="Mail" />
						</div>
					</Nav.Link>
					<Nav.Link href={`tel:${tel}`}>
						<div>
							<img src={phoneSVG} alt="Mail" />
						</div>
					</Nav.Link>
					<LangSelector />
				</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

export default Navigation;
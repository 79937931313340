import { useTranslation } from "react-i18next";
import "./body-content.styles.css";
import { CardGroup, Container } from "react-bootstrap";
import { Card, ListGroup } from 'react-bootstrap';
import { cards } from "./body-contants";

const BodyContent = (): JSX.Element => {
	const { t } = useTranslation();

	return (
		<>
		<div className="main-page-background-image" />
		<Container fluid className="page main-page-image">
		<div className="content">
			<h1 className="title text">{t("section1.title")}</h1>
			<p className="description text">{t("section1.description")}</p>
		</div>
		</Container>
		<Container fluid className="page about">
		<h1 className="title-alternative text">{t("section2.title")}</h1>
		<div>
			<ListGroup>
				{cards.map((itemsRow, index): JSX.Element => {
					return (
						<CardGroup key={index}>
							{itemsRow.map((item, i) =>  <Card key={i}>
								<Card.Body className="card" >
									<Card.Img variant="top" src={item.img} className="card-image-custom m-1 text-center" />
									<Card.Text>
										{t(`section2.items.${item.description}`)}
									</Card.Text>
								</Card.Body>
							</Card>)}
						</CardGroup>
					);
				})}
			</ListGroup>
		</div>
		</Container>
		</>
	);
}

export default BodyContent;
import img1 from "../../assets/real_estate.png";
import img2 from "../../assets/agency_service.png";
import img3 from "../../assets/legal_due.png";
import img4 from "../../assets/rental.png";
import img5 from "../../assets/resale_and_leasing.png";
import img6 from "../../assets/renovation.png";

interface CardData {
	img: string;
	description: string;
}

export const cards: CardData[][] = [
	[{ img: img1, description: "real-estate" },
	{ img: img2, description: "agency-service" },
	{ img: img3, description: "legal-due" }],
	[{ img: img4, description: "rental" },
	{ img: img5, description: "resale-and-leasing"},
	{ img: img6, description: "renovation" }]
]
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navigation from './components/navigation-container/navigation-container';
import BodyContent from './components/body-content/body-content';
import FooterContent from './components/footer-content/footer-content';

function App(): JSX.Element {

  return (
    <div className="App">
      <Navigation/>
      <BodyContent />
      <FooterContent />
    </div>
  );
}

export default App;

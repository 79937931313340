import { Container, Row, Col, ListGroup, Stack } from "react-bootstrap";
import "./footer-content.styles.css";
import mapImage from "../../assets/map_image.png";
import { useTranslation } from "react-i18next";

export const email = "fininvest.kotka@gmail.com";
export const tel = "+358505290125";

const FooterContent = (): JSX.Element => {
	const { t } = useTranslation();

	const googleApi = "";

	return (
		<Container fluid className="footer">
			<Row className="justify-content-md-center contacts-rows">
				<Col>
				<Container fluid>
					<Stack gap={1} className="col-md-5 mx-auto footer-text">
						<h2>{t("footer.contacts")}</h2>
						<ListGroup>
							<ListGroup.Item className="list-title">{t("footer.phone")}</ListGroup.Item>
							<ListGroup.Item>{tel}</ListGroup.Item>
							<ListGroup.Item>Y-tunnus: 3387344-9</ListGroup.Item>
						</ListGroup>

						<ListGroup>
							<ListGroup.Item className="list-title">{t("footer.email")}</ListGroup.Item>
							<ListGroup.Item>{email}</ListGroup.Item>
						</ListGroup>

						<ListGroup>
							<ListGroup.Item className="list-title">{t("footer.address")}</ListGroup.Item>
							<ListGroup.Item>48100, Finland, Kotka, Kotkankatu 16 Ih. 4</ListGroup.Item>
						</ListGroup>
					</Stack>
				</Container>
				</Col>
				<Col className="map-position">
					<img src={mapImage} className="map-image"/>
				</Col>
			</Row>
		</Container>
	);
}

export default FooterContent;
import React, { ChangeEvent, useState } from "react";
import i18n from "../../i18n";
import "./lang-selector.styles.css";

const LangSelector = (): JSX.Element => {
	const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

	const chooseLanguage = (e: ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		const { value } = e.target;
		i18n.changeLanguage(value);
		setSelectedLanguage(value);
		localStorage.setItem("locale", value);
	}

	return (
		<div className="custom-language-selector">
			<label className={selectedLanguage === 'en' ? "checked-label " : ""}>
				<input
					type="radio"
					value="en"
					name="language"
					checked={selectedLanguage === 'en'}
					onChange={chooseLanguage}
				/>
				En
			</label>
			<label className={selectedLanguage === 'fi' ? "checked-label " : ""}>
				<input
					type="radio"
					value="fi"
					name="language"
					checked={selectedLanguage === 'fi'}
					onChange={chooseLanguage}
				/>
				Fin
			</label>
		</div>
	  );
}

export default LangSelector;
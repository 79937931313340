import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import fiTranslations from "../locales/fi.json";
import enTranslations from "../locales/en.json";

const resources = {
	en: {
		translation: enTranslations
	}, 
	fi: {
		translation: fiTranslations
	}
}

i18n.use(initReactI18next).init({
	resources,
	lng: localStorage.getItem("locale") || "en",
	debug: false,
	fallbackLng: "en",
	interpolation: {
		escapeValue: false
	},
	ns: "translation", // namespaces help to divide huge translations into multiple small files.
	defaultNS: "translation",
	
})

export default i18n;